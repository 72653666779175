import "./App.css";
import instagram from "./icons/instagram.svg";
import { isMobile } from "react-device-detect";
function App() {
  return (
    <div className={`app ${isMobile ? "mobile" : "desktop"}`}>
      <div></div>
      <header className={`header ${isMobile ? "mobile" : "desktop"}`}>
        Creative Wonder Studio
      </header>
      <footer className="footer">
        <a
          href="https://www.instagram.com/creative.wonder.studio/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={instagram} alt="instagram icon" width="50px" />
        </a>
      </footer>
    </div>
  );
}

export default App;
